import React from "react"

export default function TournamentSelectionNotice({
  tournamentName,
  isEntryClosed,
}) {
  return (
    <>
      {tournamentName && !isEntryClosed && tournamentName !== "QMSA_AnnualMembership_2025" && tournamentName !== "AMSC_Teams_ACT_2024_0909" && (
        <>
          <em style={{ fontSize: "75%" }}>Entry Fee from $35. Pay at centre on day of tournament</em>
          <br />
        </>
      )}
      {isEntryClosed && tournamentName !== "QMSA_AnnualMembership_2025" && (
        <>
          <div style={{ backgroundColor: "orangered", color: "whitesmoke" }}>
            ⚠️ <b>Entries closed</b> ⚠️
          </div>
          See{" "}
          <a href="https://ourvoice.qmsa.asn.au/tag/tournaments/">
            tournament results on our website
          </a>{" "}
          when available.
        </>
      )}
      {tournamentName === "QMSA_AnnualMembership_2025" && (
        <>
          <div><em style={{ fontSize: "75%" }}>
            Membership fee $30 per calendar year. Pay online.&nbsp;
            <a href="https://ourvoice.qmsa.asn.au/membership/">Click here for details</a>{" "}
          </em></div>
        </>
        )}
      {tournamentName === "AMSC_Teams_ACT_2024_0909" && (
        <>
          <div><em style={{ fontSize: "75%" }}>
            <b>Enter before Monday 5th August.</b>&nbsp;
            <a href="https://ourvoice.qmsa.asn.au/amsc-teams-2024/">Click here for tournament and payment details</a>{" "}
          </em></div>
        </>
      )}
    </>
  )
}
